<template>
  <Modal ref="modal" width="unset">
    <template #header>
      <h5>Signature tutorial</h5>
    </template>

    <div class="sr__guide">
      <div class="sr__guide__header">
        <ol class="sr__guide__header-text">
          <li>Add as many Recipients as you like</li>
          <li>Enter their names and emails</li>
          <li>Group them to signing steps if you wish</li>
          <li>Place signature fields</li>
        </ol>
      </div>
      <div class="sr__guide__body">
        <div class="sr__guide__body-images">
          <img src="/images/signature_steps_tutorial_image.png" alt="tutorial" />
        </div>
      </div>
      <div class="sr__guide__actions">
        <button type="button" class="sr__button form" @click="onSubmit(skip)">Next</button>
        <div class="sr__checkbox">
          <input id="skipSignNowGuide" v-model="skip" type="checkbox" />
          <label for="skipSignNowGuide">Don't show again</label>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import type Modal from '../Modal.vue';

defineProps<{
  onSubmit: (skip: boolean) => void;
}>();

const skip = ref<boolean>(false);
const modal = ref<InstanceType<typeof Modal> | null>(null);

const openModal = () => {
  modal.value?.openModal();
};

const closeModal = () => {
  modal.value?.closeModal();
};

defineExpose({ openModal, closeModal });
</script>

<style scoped lang="scss">
.sr__guide {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;

  p {
    margin: 0;
    padding: 0;
  }
}

.sr__guide__header {
  display: flex;
  justify-content: center;
  font-size: 24px;
}

.sr__guide__body-images {
  img {
    width: 100%;
  }
}

.sr__guide__body-main-text {
  text-align: center;
  font-size: 20px;
}

.sr__guide__actions {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.sr__checkbox {
  label {
    padding-left: 12px;
    font-size: 16px;
  }
}
</style>
