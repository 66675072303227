import type { CreateSignatureDto, SignatureDto, UpdateSignatureDto } from '../../base/common/dtos/signature.dto';
import type { BaseDataTableDataModel } from '../../base/common/models/base/base-data-table-data.model';
import type { BaseDataTableUrlParamsModel } from '../../base/common/models/base/base-data-table-url-params.model';

interface State {
  loading: boolean;
  loaded: boolean;
  signatures: BaseDataTableDataModel<SignatureDto>;
  selectedSignature: SignatureDto | null;
  signNowIframeSource: string | null;
}

export interface GetSignaturesParams extends BaseDataTableUrlParamsModel<SignatureDto> {}

export const useSignaturesStore = defineStore('signaturesStore', {
  state: (): State => ({
    loading: false,
    loaded: false,
    signatures: {
      data: [],
      total: 0,
    },
    selectedSignature: null,
    signNowIframeSource: null,
  }),
  actions: {
    async createSignature(data: CreateSignatureDto) {
      const { $snapApi } = useNuxtApp();
      this.loading = true;

      try {
        const { data: signature }: { data: SignatureDto } = await $snapApi({
          method: 'POST',
          url: 'signatures',
          data,
        });

        this.signatures.data.unshift(signature);
        this.signatures.total += 1;

        return signature;
      } catch (error: unknown) {
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async updateSignature(id: SignatureDto['id'], data: UpdateSignatureDto) {
      const { $snapApi } = useNuxtApp();
      this.loading = true;

      try {
        const { data: signature }: { data: SignatureDto } = await $snapApi({
          method: 'PUT',
          url: `signatures/${id}`,
          data,
        });

        const signatureIndex = this.signatures.data.findIndex(({ id }) => id === signature.id);

        if (signatureIndex === -1) {
          this.signatures.data.unshift(signature);
          this.signatures.total += 1;
        } else {
          this.signatures.data[signatureIndex] = { ...this.signatures.data[signatureIndex], ...signature };
        }
      } catch (error: unknown) {
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async renameFile(id: number, fileName: string) {
      const { $snapApi } = useNuxtApp();
      this.loading = true;

      try {
        await $snapApi({
          method: 'PATCH',
          url: `signatures/documents/${id}`,
          data: { fileName },
        });
      } catch (e) {
        throw e;
      } finally {
        this.loading = false;
      }
    },
    async deleteFile(id: number, signatureId?: number) {
      const { $snapApi } = useNuxtApp();
      this.loading = true;

      try {
        await $snapApi({
          method: 'DELETE',
          url: `signatures/documents/${id}${signatureId ? `?signatureId=${signatureId}` : ''}`,
        });
      } catch (e) {
        throw e;
      } finally {
        this.loading = false;
      }
    },
    async getSignatures(options?: GetSignaturesParams): Promise<void> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      let url = 'signatures/datatable';

      if (options) {
        url = appendUrlDataTableParamsT(url, options);
      }

      try {
        const response = await $snapApi({
          method: 'GET',
          url,
        });

        this.signatures.data = response.data.list;
        this.signatures.total = response.data.count;
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async getSignature(id: number): Promise<SignatureDto> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;
      try {
        const { data }: { data: SignatureDto } = await $snapApi({
          method: 'GET',
          url: `signatures/${id}`,
        });

        return data;
      } catch (e) {
        throw e;
      } finally {
        this.loading = false;
      }
    },
    async downloadAttachment(id: number) {
      const { $snapApi } = useNuxtApp();

      try {
        const response = await $snapApi({
          method: 'GET',
          url: `signatures/${id}/download`,
          responseType: 'blob',
        });

        triggerDownloadFile(response, 'attachment.pdf');
      } catch (e) {
        throw e;
      }
    },
    async removeSignature(id: number) {
      const { $snapApi } = useNuxtApp();
      this.loading = true;
      try {
        await $snapApi({
          method: 'DELETE',
          url: `signatures/${id}`,
        });
      } catch {
      } finally {
        this.loading = false;
      }
    },
    async getSignNowIframeSource(id: number, isSigning: boolean): Promise<void> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      const url = `signatures/${id}/${isSigning ? 'signing' : 'editor'}`;

      try {
        const response = await $snapApi({
          method: 'GET',
          url,
        });

        this.signNowIframeSource = response.data;
      } catch (error: unknown) {
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async resendEmails(id: number): Promise<string> {
      const { $snapApi } = useNuxtApp();

      this.loading = true;

      const url = `signatures/${id}/resend-emails`;

      try {
        const response = await $snapApi({
          method: 'POST',
          url,
        });

        return response.data;
      } catch (error: unknown) {
        throw error;
      } finally {
        this.loading = false;
      }
    },
  },
});
