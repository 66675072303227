import type {
  DataTableSorterEmitParams,
  DataTableFilterEmitParams,
  DataTablePaginationEmitParams,
} from '../common/interfaces/data-table/data-table-emits.interface';
import type { BaseDataTableUrlParamsModel } from '../common/models/base/base-data-table-url-params.model';

export const getDataTableFetchOptions = <T>(
  options: DataTableSorterEmitParams | DataTableFilterEmitParams | DataTablePaginationEmitParams,
  pageSize = 10,
): BaseDataTableUrlParamsModel<T> => {
  const baseOptions = {
    take: pageSize,
    sortBy: options.sorter?.columnKey as Extract<keyof T, string>,
    sortOrder: options.sorter?.order,
    search: options.filterValue,
  };

  if ('currentPage' in options) {
    const skip = (options.currentPage - 1) * pageSize;

    return {
      skip,
      ...baseOptions,
    };
  }

  return baseOptions;
};
